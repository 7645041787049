/* =============================================================
 * import
 * ========================================================== */
import Swiper from 'swiper/bundle';
import ScrollHint from 'scroll-hint';
import $ from "jquery";
import {mediaQuery} from '../modules/common';

/* =============================================================
 * function
 * ========================================================== */

export default function exe() {
  const windowWidth = window.innerWidth;
  if (mediaQuery(windowWidth) === 'sp') {
    slideDownBtn();
  }

  tableScrollHint();
  columnSwiper();


  window.addEventListener('resize', function() {
    tableScrollHint();
    columnSwiper();
  });
}

function tableScrollHint() {
  // wrap table settings
  const contentsArea = document.querySelectorAll(".contents_post table");
  if (contentsArea !== null) {
    const tableAry = Array.prototype.slice.call(contentsArea);
    tableAry.forEach(function(e) {
      e.outerHTML = '<div class="table_wrap">' + e.outerHTML + '</div>';
    });
  }

  // scrollableJS settings
  const scrollableTrigger = document.querySelectorAll(".table_wrap");
  if (scrollableTrigger !== null) {
    const scrollableAry = Array.prototype.slice.call(scrollableTrigger);
    scrollableAry.forEach(function() {
      new ScrollHint(scrollableAry, {
        scrollHintIconAppendClass: 'scroll-hint-icon-white',
        // applyToParents: true,
        i18n: {
          scrollable: 'スクロールできます',
        },
      });
    });
  }
}

function columnSwiper() {

  const swiper = new Swiper('.swiper', { // eslint-disable-line @typescript-eslint/no-unused-vars
    // modules: [Navigation, Autoplay, Scrollbar],
    slidesPerView: 'auto', // コンテナ内に表示させるスライド数（CSSでサイズ指定する場合は 'auto'）
    speed: 1200, // スライドアニメーションのスピード（ミリ秒）

    grabCursor: true, // PCでマウスカーソルを「掴む」マークにする
    watchSlidesProgress: true, // スライドの進行状況を監視する

    /*
    autoplay: { // 自動再生させる
      delay: 3000, // 次のスライドに切り替わるまでの時間（ミリ秒）
      disableOnInteraction: true, // ユーザーが操作しても自動再生を止めない
      waitForTransition: true, // アニメーションの間も自動再生を止めない（最初のスライドの表示時間を揃えたいときに）
    },
    loop: true,  // 無限ループさせる
    loopAdditionalSlides: 1, // 無限ループさせる場合に複製するスライド数
    freeModeSticky: true,
    */

    // cssMode: true,

    freeMode: {
      enabled: true, // true：自由にドラッグ（スワイプ）できるようにする。スライド位置がスナップしなくなる
      momentum: true, // false：ドラッグ（スワイプ）した後の慣性スクロールをオフにする
      momentumRatio: .1, // 数字が大きいほど惰性スクロールの距離が伸びる（freeMode: trueとfreeModeMomentum:trueが前提）
      modeMomentumVelocityRatio: .2, // 数字が大きいほど惰性スクロールのスピードが速くなる（freeMode: trueとfreeModeMomentum:trueが前提）
      sticky: true,
    },

    preventClicks: true, // true: スワイプ中の不要なクリックを無効化
    preventClicksPropagation: true, // true: スワイプ中の不要なクリックイベントを無効化

    scrollbar: {
      el: '.swiper-scrollbar', // スクロールバー要素のクラス
      draggable: true,
    },
  });
}

function slideDownBtn() {
  const btn = $(".category_name");
  const area = $(".category_list");
  if (btn.length) {
    btn.click(function() {
      btn.toggleClass('open');
      area.toggleClass('open').slideToggle().css('display', 'flex');
    });
  }
}
