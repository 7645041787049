/* =============================================================
 * import
 * ========================================================== */
import {mediaQuery} from './common';
import {eventObserver} from "./eventObserver";
import {gsap} from "gsap/gsap-core";
import {CustomEase} from "gsap/CustomEase";

gsap.registerPlugin(CustomEase);
CustomEase.create("slide", ".32, .95, .6, 1");


/* =============================================================
 * globalNavi
 * グローバルナビに関する実行関数
 * ========================================================== */

export function globalNavi() {
  const windowWidth = window.innerWidth;
  const listElements = document.querySelectorAll('.header_gnav_list_item_link');
  const listElementsTxt = document.querySelectorAll('.header_gnav_list_child_item_txt');
  const listElementsBottom = document.querySelectorAll('.header_gnav_bottom_link');
  const hum = document.getElementById('hum');
  const logo = document.getElementById('header_logo');
  const listeners = [];
  if (mediaQuery(windowWidth) === 'sp') {
    /* ----- SP ----- */

    if (hum) {
      // SP HumbergerMneu
      hum.addEventListener('click', humberger);
      logo.addEventListener('click', humberger);
      listeners.push([hum, 'click', humberger]);
      listeners.push([logo, 'click', humberger]);

      listElements.forEach((elem) => {
        if (elem.parentNode.classList.contains('gnav_list_parent') === false) {
          elem.addEventListener('click', humberger, false);
          listeners.push([elem, 'click', humberger]);
        }
      });
      listElementsTxt.forEach((elem) => {
        if (elem.parentNode.classList.contains('gnav_list_parent') === false) {
          elem.addEventListener('click', humberger, false);
          listeners.push([elem, 'click', humberger]);
        }
      });
      listElementsBottom.forEach((elem) => {
        elem.addEventListener('click', humberger, false);
        listeners.push([elem, 'click', humberger]);
      });
    }

    eventObserver.observeEvents(listeners);
  }
}


/* =============================================================
 * humberger
 * ハンバーガーメニューの制御
 * ========================================================== */

export function humberger() {

  const html = document.documentElement;
  const hum = document.getElementById('hum');
  const header = document.getElementById('header');
  const gnav = document.getElementById('gnav');
  const filter = document.getElementById('filter');

  if (hum) {

    if (hum.classList.contains('close')) {

      // 閉じている時
      if (!this.classList.contains('header_logo_link')) {
        hum.classList.remove('close');
        header.classList.remove('close');
        gnav.classList.remove('close');
        filter.classList.remove('close');

        hum.classList.toggle('open');
        header.classList.toggle('open');
        gnav.classList.toggle('open');
        filter.classList.toggle('open');
        html.classList.toggle('is-fixed');

        humbergerOpen();
      }

    } else if (hum.classList.contains('open')) {

      // 開いている時
      hum.classList.add('close');
      header.classList.add('close');
      gnav.classList.add('close');
      filter.classList.add('close');

      hum.classList.toggle('open');
      header.classList.toggle('open');
      gnav.classList.toggle('open');
      filter.classList.toggle('open');
      html.classList.toggle('is-fixed');

      humbergerClose();
    } else {

      // 初動
      if (!this.classList.contains('header_logo_link')) {
        hum.classList.toggle('open');
        header.classList.toggle('open');
        gnav.classList.toggle('open');
        filter.classList.toggle('open');
        html.classList.toggle('is-fixed');

        humbergerOpen();
      }
    }

  }

}


function humbergerOpen() {
  const bg = document.querySelector('#hum .hum_bg');
  gsap.fromTo(bg, {
    webkitClipPath: 'inset(0 50% 0 50%)',
    clipPath: 'inset(0 50% 0 50%)',
  }, {
    webkitClipPath: 'inset(0 0% 0 0%)',
    clipPath: 'inset(0 0% 0 0%)',
    duration: .5,
    ease: "slide",
  });
}

function humbergerClose() {
  const bg = document.querySelector('#hum .hum_bg');
  gsap.fromTo(bg, {
    webkitClipPath: 'polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 100% 100%, 100% 0%)',
    clipPath: 'polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 100% 100%, 100% 0%)',
  }, {
    webkitClipPath: 'polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 0%)',
    clipPath: 'polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 0%)',
    duration: 1,
    ease: "slide",
  });
}

/* =============================================================
 * checkParentDirectory
 * 現在のディレクトリ名からサブページの有無を判定
 * ========================================================== */
export function checkParentDirectory(path) {
  let reg;
  let result;
  let hasChildDir = false;

  // 表示中のページ判定
  const childPathArray = ['/service/', '/works/', '/company/', '/about/', '/media/', '/contact/'];
  childPathArray.forEach(dir => {
    reg = new RegExp(dir);
    result = path.match(reg);
    if (result != null) {
      hasChildDir = true;
      return;
    }
  });
  return hasChildDir;
}

