/* =============================================================
 * import
 * ========================================================== */
import $ from 'jquery';

/* =============================================================
 * function
 * ========================================================== */

export default function exe() {
  googleReCaptchaV3();
  formAddErrorClass();
  exchangeForm();
  customSelectBox();
}


function formAddErrorClass() {
  if ($(".mw_wp_form").length) {
    $(".form_list_cnt").each(function() {
      if ($(this).find('.error').length) {
        $(this).addClass('error_field');
      }
    });
  }
}


function exchangeForm() {
  if ($(".mw_wp_form").length) {
    const triggerName = 'お問い合わせ種別';
    $('[name="' + triggerName + '"]:radio').change(function() {
      exchangeFormTable($('[name="' + triggerName + '"]:checked').val());
    });
    exchangeFormTable($('[name="' + triggerName + '"]:checked').val());
  }
}


function exchangeFormTable(v) {
  if (v === 'その他（採用に関するお問い合わせはこちらから）') {
    $('.list_other').slideDown();
  } else {
    $('.list_other').slideUp();
  }
  /*
  if (v === '選択肢１' || v === '選択肢２' ) {
    $('.desc_data').slideDown();
    const msgCourse = '（例）以下のコースへの申し込みを検討しています。\nコース名：' + $("#course_target").val() + $("#course_name").val() + $("#course_plan_class").val() + $("#course_plan_name").val();
    const msgTrial = '無料体験レッスンに申し込みます。';
    const msg = $('#message').val();
    if (v === '選択肢１' && (msg === '' || msg === msgTrial) ) {
      $('#message').val(msgCourse);
    }

    if (v === '選択肢２' && (msg === '' || msg === msgCourse) ) {
      $('#message').val(msgTrial);
    }

  } else {
    $('.desc_data').slideUp();
    $('#message').val('');
  }
  */
}


function googleReCaptchaV3() {
  const siteKey = '6Le4LJojAAAAANu87Q2kV0LTr9hd_otp4ZfL0qFo';

  // googleReCaptchaV3の読み込み（プラグイン等でのデフォルトの読み込みを解除している場合のみ実行）
  const sc = document.createElement('script');
  const url = 'https://www.google.com/recaptcha/api.js?render=' + siteKey;
  const ref = document.querySelector('#js-app');
  sc.src = url;
  document.body.insertBefore(sc, ref);

  // 2分制限を解除する設定
  const mwWpForm = document.querySelector('.mw_wp_form > form');
  const mwWpFormSubmit = document.querySelector('[name="submitConfirm"]');
  const mwWpFormBtnVal = mwWpFormSubmit.value;
  mwWpForm.addEventListener('submit', onReCAPTCHA);
  mwWpForm.insertAdjacentHTML('beforeend', '<input type="hidden" name="submitConfirm" value="' + mwWpFormBtnVal + '">');

  // リキャプチャの制御
  function onReCAPTCHA(e) {
    e.preventDefault();
    grecaptcha.ready(function() { // eslint-disable-line no-undef
      grecaptcha.execute(siteKey, { // eslint-disable-line no-undef
        action: 'submit',
      }).then(function(token) {
        const recaptchaResponse = document.querySelector('input[name="recaptcha-v3"]');
        recaptchaResponse.value = token;
        mwWpForm.submit();
      });
    });
  }

  const mwWpFormPrivacy = document.querySelector('#privacy-1');
  disabledSubmit(mwWpFormPrivacy);
  mwWpFormPrivacy.addEventListener('change', disabledSubmit);

  // プライバシーポリシーのチェックに応じてボタンをdisabled
  function disabledSubmit(event) {
    let flg;
    if (event.target === undefined) {
      flg = event.checked;
    } else {
      flg = event.target.checked;
    }

    if (flg == true) {
      mwWpFormSubmit.disabled = false;
    } else {
      mwWpFormSubmit.disabled = true;
    }
  }
}


// セレクトボックスのデフォルト項目カスタマイズ
function customSelectBox() {
  if ($('#mw_wp_form_mw-wp-form-51')) {
    let val = '';
    $('#mw_wp_form_mw-wp-form-51 select option[value=""]').html('選択してください。');

    if (getParam('job_id')) {
      const jobParam = decodeURI(getParam('job_id'));
      // console.dir(jobParam);
      $('#job > option').each(function() {
        val = $(this).attr('value');
        if (val === jobParam) {
          $(this).attr('selected', 'selected');
        }
      });
    }
  }
}


// GETパラメータのデータ取得
function getParam(param){
  const arg  = [];
  const url = location.search.substring(1).split('&');

  for(let i=0; url[i]; i++) {
    const k = url[i].split('=');
    arg[k[0]] = k[1];
  }
  return arg[param];
}
