/* Outline
 * app.js -> this file.
 * |
 * | - /barba/
 * |    barbajs管理用のディレクトリ
 * |    barbajs : https://barba.js.org/
 * |    シームレスな画面遷移を実現するためのPJAXライブラリ。
 * |
 * | - /route/
 * |    ページ管理用のディレクトリ
 * |    ページ単体での動作等が必要な場合に用いる。
 * |    作成した関数は、barba/exe.jsのviewで管理、適応する。
 * |
 * | - /modules/
 * |    各モジュールの管理用ディレクトリ
 * |    モジュールではないが、大きめの挙動を管理する場合、活用OK。
 * */

/* ********************************************
 * import
 * ****************************************** */
import 'core-js/stable'; // as polyfill
import 'regenerator-runtime/runtime'; // as polyfill
import 'intersection-observer'; // scroll
import 'jquery-easing';

import barbaRun from './app/barba/exe';
import * as Common from './app/modules/common';
import {floating} from './app/modules/scrollObserver';
import {globalNavi} from './app/modules/gnav';
import adjusterIE from './app/modules/adjusterIE';
import {parallax} from "./app/modules/parallax";


/* =============================================================
 * variables
 * ========================================================== */
const $body = document.body;


/* ********************************************
 * exe
 * ****************************************** */
barbaRun(); // barbajsの実行
parallax(); // LocomotiveScrollの実行


/* ********************************************
 * exe - landing
 * ****************************************** */
document.addEventListener('DOMContentLoaded', function() {
  // routeCommon.cursor();
  // routeCommon.cursorAnimation();
  $body.classList.add('nowloading');


  // スマホのwindowサイズ（高さ）を取得
  /iPhone|iPod|iPad|Android/i.test(navigator.userAgent) &&
  document.documentElement.style.setProperty(
    '--outer-height',
    window.innerHeight + 'px',
  );

  globalNavi();
  adjusterIE();
  floating();
  Common.txtUp();
  Common.lineWrap();
});


/* ********************************************
 * exe - loaded
 * ****************************************** */
window.addEventListener('load', function() {
  $body.classList.remove('nowloading');
  $body.classList.add('done', 'loaded');
  Common.scrollResetOnLoad();
});


/* ********************************************
 * exe - window.resize
 * ****************************************** */
window.addEventListener('resize', function() {
  parallax(); // LocomotiveScrollの実行
});