
class LineWrap {
  constructor(target) {

    this.target = this.convertElement(target);
    this.inner = this.target.innerText;
    this.convert();
  }

  convert() {

    let spanWrapLine = "";
    const text = this.inner.replace(/\r?\n/g, '</span><span>');// 改行コードを<span>に変換
    spanWrapLine = "<span>" + text + "</span>";

    this.target.innerHTML = spanWrapLine;

  }

  // jQueryオブジェクトや文字列セレクターを変換
  convertElement(element) {
    if (element instanceof HTMLElement) {
      return element;
    }
    /*
    if (element instanceof jQuery) {
      return element[0];
    }
    */
    return document.querySelector(element);
  }

}

export default LineWrap;