/* =============================================================
 * import
 * ========================================================== */
import $ from "jquery";
import {mediaQuery} from '../modules/common';
import {eventObserver} from "../modules/eventObserver";

/* =============================================================
 * function
 * ========================================================== */

export default function exe() {
  const windowWidth = window.innerWidth;
  if (mediaQuery(windowWidth) !== 'sp') {
    imgSwitcher();
  }
}



function imgSwitcher() {
  const listeners = [];
  const btn = $(".js-switcherBtn");
  const area = $(".js-switcherImg");
  const obj = {
    btn: btn,
    area: area,
  };
  const onSwitcher = (e) => {
    e.data.area.removeClass('open');
    $("#" + e.currentTarget.dataset.switcherTarget).addClass('open');
  };

  if (btn.length) {
    btn.on('mouseenter', obj, onSwitcher);
    // btn.on('mouseleave', obj, onSwitcher);
    listeners.push([btn, 'mouseenter', onSwitcher, '$']);
    // listeners.push([btn, 'mouseleave', onSwitcher, '$']);
  }

  eventObserver.observeEvents( listeners );
}

