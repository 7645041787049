/* =============================================================
 * import
 * ========================================================== */
import bowser from 'bowser';
import {gsap} from "gsap/gsap-core";
import {CustomEase} from "gsap/CustomEase";

const browser = bowser.getParser(window.navigator.userAgent);
gsap.registerPlugin(CustomEase);
CustomEase.create("slide", ".32, .95, .6, 1");

/* =============================================================
 * function
 * ========================================================== */


export default function exe(data) {
  const namespace = data.current.namespace; // barbajsからdataを取得
  if (namespace == '') {
    // 遷移元ページが存在しない場合（TOPページにランディングした場合）
    opening();
  } else {
    // 遷移元ページが存在する場合
    kvSlider();
  }
  kvMouseStoker();
}


function opening() {
  // TOPページのみ最優先で実行
  // 他ページからの遷移では実行しないため、barbaJSを回避
  const opening = document.getElementById('opening');
  if (opening != null) {

    const logoMark = document.querySelector('#opening .svg_logo_mark');
    const logoMarkPath = document.querySelector('#opening .svg_logo_mark path');
    const logoType = document.querySelector('#opening .svg_logo_type');
    const logoCopy = document.querySelector('#opening .opening_copy');
    const bg1 = document.querySelector('#opening .opening_bg.layer1');
    const bg2 = document.querySelector('#opening .opening_bg.layer2');
    const hum = document.querySelector('#hum');
    const logoMarkScale = window.innerWidth / logoMark.clientWidth;

    const shapeBefore  = 'M60.4375 97.4375L86 80.75L13.347 37.5122L0 45.4822V127.55L24.541 112.909V76.0422L60.4375 97.4375ZM24.664 120.426L0.15 135.053L75 179.71L98.956 165.41L24.664 120.426ZM86 80.75L60.4375 97.4375L136.653 142.925L150 134.958V52.9032L125.458 67.5422V104.395L86 80.75ZM125.336 60.0262L149.864 45.3992L75 0.742188L51.044 15.0282L125.336 60.0262Z';
    const shapeAfter = 'M50.5 91.5262L77.193 75.6062L13.347 37.5122L0 45.4822V127.55L24.541 112.909V76.0422L50.5 91.5262ZM24.664 120.426L0.15 135.053L75 179.71L98.956 165.41L24.664 120.426ZM99.5 88.9122L72.807 104.846L136.653 142.925L150 134.958V52.9032L125.458 67.5422V104.395L99.5 88.9122ZM125.336 60.0262L149.864 45.3992L75 0.742188L51.044 15.0282L125.336 60.0262Z';

    const tl = gsap.timeline({
      repeat: 0,
      ease: "slide",
      // onComplete: kvSlider,
    });

    tl
      .set(hum, {
        autoAlpha: 0,
      })
      .set(logoMark, {
        autoAlpha: 1,
        scale: logoMarkScale*3,
        rotate: 0,
      })
      .set(logoMarkPath, {
        attr: { d: shapeBefore },
      })
      .set(logoCopy, {
        webkitClipPath: 'inset(0% 100% 0% 0%)',
        clipPath: 'inset(0% 100% 0% 0%)',
      })

      .fromTo(logoType, {
        webkitClipPath: 'inset(0% 100% 0% 0%)',
        clipPath: 'inset(0% 100% 0% 0%)',
        autoAlpha: 0,
      }, {
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        delay: .4,
        duration: .8,
        autoAlpha: 1,
      })

      .fromTo(bg1, {
        webkitClipPath: 'polygon(0 0, 0 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 100% 100%, 100% 0%)',
        clipPath: 'polygon(0 0, 0 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 100% 100%, 100% 0%)',
      }, {
        webkitClipPath: 'polygon(0 0, 0 100%, 0% 100%, 0% 0, 100% 0, 100% 100%, 100% 100%, 100% 0%)',
        clipPath: 'polygon(0 0, 0 100%, 0% 100%, 0% 0, 100% 0, 100% 100%, 100% 100%, 100% 0%)',
        duration: 1.6,
      }, "+=.2")

      .to(logoMarkPath, {
        attr: { d: shapeAfter },
        duration: .4,
      }, "-=1.2")

      .to(logoMark, {
        scale: 1,
        rotate: 360,
        duration: 1.2,
        // ease: "Power3.easeOut",
      }, "-=1.0")

      .addLabel("logoMarkShrink", ">")

      .to(logoType, {
        autoAlpha: 0,
        duration: .6,
      }, "-=1")

      .to(logoCopy, {
        autoAlpha: 1,
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: 1.5,
      }, "logoMarkShrink+=.1")

      .fromTo(bg2, {
        webkitClipPath: 'polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 100% 100%, 100% 0%)',
        clipPath: 'polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 100% 100%, 100% 0%)',
        // webkitClipPath: 'polygon(0% 0%, 0% 100%, 75vw 100%, 25vw 0, 25vw 0%, 75vw 100%, 100% 100%, 100% 0%)',
        // clipPath: 'polygon(0% 0%, 0% 100%, 75vw 100%, 25vw 0, 25vw 0%, 75vw 100%, 100% 100%, 100% 0%)',
      }, {
        // webkitClipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 100%, 100% 100%, 100% 0%)',
        // clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 100%, 100% 100%, 100% 0%)',
        webkitClipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0, 100% 0, 100% 100%, 100% 100%, 100% 0%)',
        clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0, 100% 0, 100% 100%, 100% 100%, 100% 0%)',
        duration: .8,
      }, "+=.8")

      .fromTo(opening, {
        autoAlpha: 1,
      }, {
        autoAlpha: 0,
        duration: .4,
      }, "-=0.6")

      .to(hum, {
        autoAlpha: 1,
      }, "<")

      .call(kvSlider, null, "-=1.6")
    ;

  } else {
    kvSlider(0);
  }

}


function kvSlider() {
  const slider01 = document.querySelectorAll('.copy');
  const slider02 = document.querySelectorAll('.kv_figure_item');
  const slider03 = document.querySelectorAll('.kv_bg_item');
  const d = 6000;

  const targetElm = [
    slider01, slider02, slider03,
  ];

  targetElm.forEach(e => {
    const max = e.length;
    if (max > 0) {
      e.forEach((item, i) => {
        item.dataset.index = max - i;
      });
    }
  });

  figureSlider(slider02, d, targetElm);
}


/*
function classCycler(opt) {

  const item = opt.targetElm;
  const max = item.length;
  let index = 0;
  let i = max;
  let indexBefore = item.length - 1;
  let timer;

  if (opt.startImmediate) {
    item[index].classList.add(opt.classNameShow);
  }

  item.forEach(e => {
    e.dataset.index = i;
    i = (--i === 0) ? max : i;
  });

  const execCycle = () => {

    if (!opt.oneWay) {
      item[index].classList.remove(opt.classNameShow);
      item[indexBefore].classList.remove(opt.classNameHide);
    }

    index = (++index === max) ? 0 : index;
    item[index].classList.add(opt.classNameShow);

    indexBefore = (++indexBefore === max) ? 0 : indexBefore;
    item[indexBefore].classList.add(opt.classNameHide);

    item.forEach(e => {
      i = e.dataset.index;
      i = (++i === max + 1) ? 1 : i;
      if (!e.classList.contains('kv_figure_item')) {
        e.style.zIndex = i; // z-indexを設定
      }
      e.dataset.index = i; // data-indexを上書き
    });

    if (opt.oneWay && index === 0) {
      clearInterval(timer);
    }
  };

  setTimeout(function() {
    timer = setInterval(execCycle, opt.duration);
  }, opt.firstDelay);

}
*/


function figureSlider(item, d, ary) {
  if (item.length > 0) {

    const duration = 1;
    const interval = d / 1000;
    const delay = interval - duration; // ベースとなる遅延秒
    const delay2 = interval - 4; // 左から右に戻る際の遅延秒（アニメーション分の時間調整）
    const margin = 100;

    item.forEach((e, i) => {

      if (i === 0) {

        // 1枚目
        const tl = gsap.timeline({
          repeat: -1,
          repeatDelay: delay,
          ease: "slide",
        });

        tl
          .to(e, {
            duration: duration,
            rotationZ: 4.86,
            rotationY: -20,
            x: 0,
            z: 0,
            zIndex: 3,
          })
          .call(slideChange, [i, ary, d, delay], "<")

          .to(e, {
            duration: duration,
            rotationZ: 5.69,
            rotationY: -20,
            x: -4 * margin,
            z: 0,
            zIndex: 4,
          }, "+=" + delay)
          .to(e, {
            duration: .5,
            opacity: 0,
            zIndex: 4,
          }, "-=.5")
          .to(e, {
            duration: 0,
            opacity: 0,
            x: 3 * margin,
            z: -3 * margin,
            zIndex: 0,
          }, "+=.5")
          .to(e, {
            opacity: 1,
            duration: 1,
            rotationZ: 0,
            rotationY: -20,
            x: 2 * margin,
            z: -2 * margin,
          }, "+=1")
          .to(e, {
            duration: 1,
            rotationZ: 0,
            rotationY: -20,
            x: 2 * margin,
            z: -2 * margin,
            opacity: 1,
          }, "-=.5")


          .to(e, {
            duration: duration,
            rotationZ: 0,
            rotationY: -20,
            x: margin,
            z: -margin,
            zIndex: 1,
          }, "+=" + delay2)
        ;

      } else if (i === 1) {

        // 2枚目
        const tl = gsap.timeline({
          repeat: -1,
          repeatDelay: delay2,
          ease: "slide",
        });

        tl
          .to(e, {
            duration: duration,
            rotationZ: 0,
            rotationY: -20,
            x: margin,
            z: -margin,
            zIndex: 1,
          })

          .to(e, {
            duration: duration,
            rotationZ: 4.86,
            rotationY: -20,
            x: 0,
            z: 0,
            zIndex: 3,
          }, "+=" + delay)
          .call(slideChange, [i, ary, d, delay], "<")

          .to(e, {
            duration: duration,
            rotationZ: 5.69,
            rotationY: -20,
            x: -4 * margin,
            z: 0,
            zIndex: 4,
          }, "+=" + delay)
          .to(e, {
            duration: .5,
            opacity: 0,
            zIndex: 4,
          }, "-=.5")
          .to(e, {
            duration: 0,
            opacity: 0,
            x: 3 * margin,
            z: -3 * margin,
            zIndex: 0,
          }, "+=.5")
          .to(e, {
            opacity: 1,
            duration: 1,
            rotationZ: 0,
            rotationY: -20,
            x: 2 * margin,
            z: -2 * margin,
          }, "+=1")
          .to(e, {
            duration: 1,
            rotationZ: 0,
            rotationY: -20,
            x: 2 * margin,
            z: -2 * margin,
            opacity: 1,
          }, "-=.5")
        ;

      } else {

        // 3枚目以降
        const tl = gsap.timeline({
          repeat: -1,
          repeatDelay: delay,
          ease: "slide",
        });

        tl
          .to(e, {
            duration: duration,
            rotationZ: 5.69,
            rotationY: -20,
            x: -4 * margin,
            z: 0,
            zIndex: 4,
          })
          .to(e, {
            duration: .5,
            opacity: 0,
            zIndex: 4,
          }, "-=.5")
          .to(e, {
            duration: 0,
            opacity: 0,
            x: 3 * margin,
            z: -3 * margin,
            zIndex: 0,
          }, "+=.5")
          .to(e, {
            opacity: 1,
            duration: 1,
            rotationZ: 0,
            rotationY: -20,
            x: 2 * margin,
            z: -2 * margin,
          }, "+=1")
          .to(e, {
            duration: 1,
            rotationZ: 0,
            rotationY: -20,
            x: 2 * margin,
            z: -2 * margin,
            opacity: 1,
          }, "-=.5")

          .to(e, {
            duration: duration,
            rotationZ: 0,
            rotationY: -20,
            x: margin,
            z: -margin,
            zIndex: 1,
          }, "+=" + delay2)

          .to(e, {
            duration: duration,
            rotationZ: 4.86,
            rotationY: -20,
            x: 0,
            z: 0,
            zIndex: 3,
          }, "+=" + delay)
          .call(slideChange, [i, ary, d, delay], "<")
        ;

      }

    });

  }

}

function slideChange(i, ary, duration, delay) {
  const opt = {
    classNameShow: 'active',  // 付与するクラス名
    classNameHide: 'hidden',  // 付与するクラス名
    firstDelay: delay,        // 初めのクラス付与までの遅延時間
    duration: duration,              // 間隔
    startImmediate: true,     // 初期状態から1つ目に付与するのか
    oneWay: false,            // 付け替えじゃなくて、片道だけ付けて終わりたいか
  };

  ary.forEach(e => {

    const max = e.length;
    const index = i;
    let indexBefore = index + 1;

    // 各アイテムのZindexをdataset.indexを元に再計算
    e.forEach(item => {
      let n = item.dataset.index;
      n = (++n === max + 1) ? 1 : n;
      if (!item.classList.contains('kv_figure_item')) {
        item.style.zIndex = n; // z-indexを設定
      }
      item.dataset.index = n; // data-indexを上書き
      item.classList.remove(opt.classNameShow);
      item.classList.remove(opt.classNameHide);
    });

    // active, hiddenクラスの付け替え
    indexBefore = (index + 1 === max) ? 0 : indexBefore;
    e[index].classList.add(opt.classNameShow);
    e[indexBefore].classList.add(opt.classNameHide);
  });
}

export function kvMouseStoker() {

  const wrap = document.querySelector('#kv'); // target

  if (wrap && (!browser.is("mobile") && !browser.is("tablet")) ) {
    const targetFigure = wrap.querySelectorAll('.kv_figure_item_link');
    const targetFigureInner = wrap.querySelectorAll('.kv_figure_img');

    const w = window.innerWidth;   // マウス移動領域の横幅（通常はスクロールバーを除く描画領域）
    const h = window.innerHeight;  // マウス移動領域の高さ（通常はスクロールバーを除く描画領域）

    const mouse = {x: 0, y: 0};
    const ratio = {x: 0, y: 0};
    const rotate = {x: 0, y: 0};
    const translate = {s: {x: 0, y: 0}, l: {x: 0, y: 0}};

    const deg = 2;   // 最大傾斜（deg）
    const pxS = 10;   // 最大移動幅（px）
    const pxL = 50;   // 最大移動幅（px）

    wrap.addEventListener('mousemove', function(e) {
      mouse.x = e.clientX;    // マウス位置 - 横
      mouse.y = e.clientY;    // マウス位置 - 縦
      ratio.x = mouse.x / w;  // マウス移動率（横）（×100で%）
      ratio.y = mouse.y / h;  // マウス移動率（縦）（×100で%）
      move(ratio.x, ratio.y);
    });

    const move = (x, y) => {
      ratio.x = x;                                   // 移動率（横）（×100で%）
      ratio.y = y;                                   // 移動率（縦）（×100で%）
      rotate.x = (ratio.y * deg * 2) - deg;       // 回転率（横）
      rotate.y = (ratio.x * deg * 2) - deg;       // 回転率（縦）
      translate.s.x = (ratio.x * pxS * 2) - pxS;  // 移動率（横）
      translate.s.y = (ratio.y * pxS * 2) - pxS;  // 移動率（縦）
      translate.l.x = (ratio.x * pxL * 2) - pxL;  // 移動率（横）
      translate.l.y = (ratio.y * pxL * 2) - pxL;  // 移動率（縦）

      targetFigure.forEach(elem => {
        gsap.to(elem, {
          duration: 2,
          rotationX: rotate.x,
          rotationY: -rotate.y,
          x: translate.s.x,
          y: translate.s.y,
          ease: "Power4.easeOut",
        });
      });

      targetFigureInner.forEach(elem => {
        gsap.to(elem, {
          duration: 3,
          x: translate.l.x,
          y: translate.l.y,
          ease: "Power4.easeOut",
        });
      });

    };
  }

}

