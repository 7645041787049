/* =============================================================
 * import
 * ========================================================== */
import {locoScroll} from "../modules/parallax";
import {eventObserver} from "../modules/eventObserver";


/* =============================================================
 * export function
 * ========================================================== */
export function before() {
  const $body = document.body;
  $body.classList.remove('top_page');
  $body.classList.add('sub_page');
}


export function after() {
  const listeners = [];
  const btn = document.querySelectorAll('.totop');
  if (btn.length > 0) {
    btn.forEach(elem => {
      elem.addEventListener('click', toTop, false);
      listeners.push([elem, 'click', toTop, false]);
    });
  }
  // Common.txtUp();
  eventObserver.observeEvents( listeners );
}


/* =============================================================
 * function
 * ========================================================== */

export function toTop() {
  locoScroll.scrollTo('top', {
    duration: 100,
  });
}

