/* =============================================================
 * import
 * ========================================================== */
import Swiper from 'swiper/bundle';


/* =============================================================
 * function
 * ========================================================== */
export default function exe() {
  interviewSwiper();
}

export function interviewSwiper() {

  const swiperInterview = new Swiper('.swiper', { // eslint-disable-line @typescript-eslint/no-unused-vars
    slidesPerView: 'auto', // コンテナ内に表示させるスライド数（CSSでサイズ指定する場合は 'auto'）
    speed: 1200, // スライドアニメーションのスピード（ミリ秒）

    // cssMode: true,              // true: CSS Scroll Snap APIが適用
    // mousewheel: true,           // true: マウスホイールでの操作を許可
    // keyboard: true,             // true: キーボードでの操作を許可
    grabCursor: true,              // PCでマウスカーソルを「掴む」マークにする
    watchSlidesProgress: true,     // スライドの進行状況を監視する

    freeMode: {
      enabled: true, // true：自由にドラッグ（スワイプ）できるようにする。スライド位置がスナップしなくなる
      momentum: true, // false：ドラッグ（スワイプ）した後の慣性スクロールをオフにする
      momentumRatio: .8, // 数字が大きいほど惰性スクロールの距離が伸びる（freeMode: trueとfreeModeMomentum:trueが前提）
      modeMomentumVelocityRatio: .2, // 数字が大きいほど惰性スクロールのスピードが速くなる（freeMode: trueとfreeModeMomentum:trueが前提）
      sticky: false,  // true:スナップを有効にする
    },
    loop: false, // ループ
    preventClicks: true, // true: スワイプ中の不要なクリックを無効化
    preventClicksPropagation: true, // true: スワイプ中の不要なクリックイベントを無効化

    /*
    navigation: {
      nextEl: '.swiper-button-next', // 「次へ」ボタン要素のクラス
      prevEl: '.swiper-button-prev', // 「前へ」ボタン要素のクラス
    },

    scrollbar: {
      el: '.swiper-scrollbar', // スクロールバー要素のクラス
      draggable: true,
    },
    */

  });

  // return mySwiper;
}
